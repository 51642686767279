import { getHeaders, SubmissionURL } from './config';
import { checkStatus, getAll, getData } from './normalizer';
import FetchTokenResponse, {
    Loss,
    SubmissionDetailsPost,
    SubmissionInspectionData,
    SubmissionUnderwritingStatus,
} from 'types/submission.type';
import { setTypes } from 'helpers/typeHelper';
import { Document2 } from 'types/document.type';
import { LogCreateOptions } from '../types/logs.types';
import { LocationClearingRequest } from '../views/SubmissionDetails/components/Clearing/components/ClearingRequestData';
import { RestoreLocationsRequest } from '../types/underwriting.type';

export async function getSubmissions(producerId: string | null = null, filterEndorsements: boolean = true) {
    const url =
        `${SubmissionURL}/submissions?filterEndorsementsWhenIssued=${filterEndorsements}` +
        (producerId ? `&producer_id=${producerId}` : '');
    return fetch(url, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(checkStatus)
        .then(getAll)
        .then(setTypes);
}

export async function getClearingSubmissions(id: string) {
    return fetch(`${SubmissionURL}/clearingItems/${id}`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(checkStatus)
        .then(getAll)
        .then(setTypes);
}

export async function getOneSubmission(id: string) {
    return fetch(`${SubmissionURL}/submission/${id}`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(checkStatus)
        .then(getData)
        .then(setTypes);
}

export async function createSubmission(payload: SubmissionDetailsPost) {
    return fetch(`${SubmissionURL}/submissions`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    })
        .then(checkStatus)
        .then(getData);
}

export async function createLocation(id: string, payload: Location[]) {
    return fetch(`${SubmissionURL}/submission/${id}/locations`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({ locations: payload }),
    })
        .then(checkStatus)
        .then(getData);
}

export async function updateLocationUWNote(submissionId: string, locationId: string, uwNote: string) {
    const url = `${SubmissionURL}/submission/${submissionId}/locations/${locationId}`;
    const payload = { underwriterNote: uwNote };
    const options = {
        method: 'PATCH',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    };
    return fetch(url, options).then(checkStatus);
}

export async function deleteSubmission(id: string) {
    return fetch(`${SubmissionURL}/submission/${id}`, {
        method: 'DELETE',
        headers: getHeaders(),
    }).then(checkStatus);
}

export async function createLosses(id: string, payload: { propertyLosses: Loss[] }) {
    return fetch(`${SubmissionURL}/submission/${id}/property-losses`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    }).then(checkStatus);
}

export async function skipLosses(id: string, payload: { skipNarrative: string }) {
    return fetch(`${SubmissionURL}/submission/${id}/skip-property-losses`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    }).then(checkStatus);
}

export async function deleteLoss(id: string, lossId: string, isClaim: boolean = false, note: string = '') {
    return fetch(`${SubmissionURL}/submission/${id}/property-losses`, {
        method: 'DELETE',
        headers: getHeaders(),
        body: note.length ? JSON.stringify({ id: lossId, note: note, isClaim: isClaim }) : '',
    }).then(checkStatus);
}

export async function createDocsAndNarrative(
    id: string,
    payload: { documents: Document2[]; narrative: string; productLineCodes: string[] }
) {
    return fetch(`${SubmissionURL}/submission/${id}/narrativeDocuments`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    }).then(checkStatus);
}

export async function addDocumentsToSubmission(id: string, payload: { documents: any[] }) {
    return fetch(`${SubmissionURL}/submission/${id}/documents`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    }).then(checkStatus);
}

export async function createNarrative(id: string, payload: { narrativeText: string }) {
    return fetch(`${SubmissionURL}/submission/${id}/narrative`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    }).then(checkStatus);
}

export async function clearForUnderwriting(id: string, clearingData: LocationClearingRequest) {
    return fetch(`${SubmissionURL}/submission/${id}/clearForUnderwriting`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(clearingData),
    }).then(checkStatus);
}

export async function blockForUnderwriting(id: string, opts?: LogCreateOptions) {
    return fetch(`${SubmissionURL}/submission/${id}/blockForUnderwriting`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(opts),
    }).then(checkStatus);
}

export async function unblockForUnderwriting(id: string) {
    return fetch(`${SubmissionURL}/submission/${id}/unblockForUnderwriting`, {
        method: 'POST',
        headers: getHeaders(),
    }).then(checkStatus);
}

export async function withdrawBindRequest(id: string) {
    return fetch(`${SubmissionURL}/submission/${id}/withdrawBindRequest`, {
        method: 'POST',
        headers: getHeaders(),
    }).then(checkStatus);
}

export async function updateSubmission(id: string, submission: SubmissionDetailsPost) {
    return fetch(`${SubmissionURL}/submissions`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(submission),
    })
        .then(checkStatus)
        .then(getData);
}

export async function updateSubmissionUnderwritingStatus(id: string, newStatus: SubmissionUnderwritingStatus) {
    return fetch(`${SubmissionURL}/submission/${id}/uwReviewStatus`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(newStatus),
    })
        .then(checkStatus)
        .then(getData);
}

export async function updateSubmissionUnderwritingNotes(id: string, uwNotes: string) {
    const uwNotesPayload = { underwritingNotes: uwNotes };
    return fetch(`${SubmissionURL}/submission/${id}/underwriting-notes`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(uwNotesPayload),
    })
        .then(checkStatus)
        .then(getData);
}

export async function updateSubmissionInspectionData(id: string, inspectionData: SubmissionInspectionData) {
    return fetch(`${SubmissionURL}/submission/${id}/inspection`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(inspectionData),
    })
        .then(checkStatus)
        .then(getData);
}

export async function voidSubmission(id: string) {
    return fetch(`${SubmissionURL}/submission/${id}/void`, {
        method: 'POST',
        headers: getHeaders(),
    }).then(checkStatus);
}

export async function restoreSubmission(id: string) {
    return fetch(`${SubmissionURL}/submission/${id}/restore`, {
        method: 'POST',
        headers: getHeaders(),
    }).then(checkStatus);
}

export async function renewSubmission(id: string) {
    return fetch(`${SubmissionURL}/submission/${id}/renew`, {
        method: 'POST',
        headers: getHeaders(),
    })
        .then(checkStatus)
        .then(getData);
}

export async function fetchBetterviewWebToken(): Promise<FetchTokenResponse> {
    return fetch(`${SubmissionURL}/betterview/webtoken`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(checkStatus)
        .then(getData);
}

export async function restoreLocationsForUW(id: string, restoreRequest: RestoreLocationsRequest): Promise<void> {
    return fetch(`${SubmissionURL}/submission/${id}/restore-locations-for-uw`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(restoreRequest),
    }).then(checkStatus);
}
