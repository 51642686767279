import * as React from 'react';
import { Typography, Card } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import Spacing from './Spacing';

interface Props {
    children?: React.ReactNode;
    title?: string;
    disclaimer?: string | React.ReactNode;
    fillHeight?: boolean;
}

const Page: React.FunctionComponent<Props> = (props) => {
    const { children, disclaimer, fillHeight, title } = props;

    return (
        <Card style={{ height: fillHeight ? '100%' : 'default' }}>
            {(title || disclaimer) && (
                <>
                    <Grid container spacing={0} alignItems="center">
                        <Grid xs={7}>{title && <Typography variant="h4">{title}</Typography>}</Grid>
                        <Grid xs={5}>
                            {disclaimer && (
                                <Typography variant="caption" align="right">
                                    {disclaimer}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    <Spacing height="24px" />
                </>
            )}
            {children}
        </Card>
    );
};

export default Page;
